import { BsX, BsSearch } from "react-icons/bs";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useEditorContext } from "../../context/EditorContext";
import { colors, fontSize } from "../../constant";
import { UpinSelectModal } from "../../ui/modals/upin/UpinSelectModal";
import { toast } from "react-hot-toast";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

const SearchComponent = ({ click }) => <BsSearch onClick={click} />;

const EditorHeader = () => {
  const [searchModal, setSearchModal] = useState(false);
  const [upinSelect, setUpinSelect] = useState(false);
  const quill = useEditorContext().quill;
  const toggleSearch = () => {
    setSearchModal(!searchModal);
    quill.formatText(0, quill.getText().length, "SearchedString", false);
  };

  return (
    <>
      <div className="toolbar-2">
        <span className="ql-formats">
          <select className="ql-font" defaultValue="arial">
            <option value="arial">Arial</option>
            <option value="comic-sans">Comic Sans</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida">Lucida</option>

            <option value="verdana">Verdana</option>
            <option value="tahoma">Tahoma</option>
            <option value="roboto">Roboto</option>
            <option value="lato">Lato</option>
            <option value="poppins">Poppins</option>

            <option value="raleway">Raleway</option>
            <option value="oswald">Oswald</option>
            <option value="nunito">Nunito</option>
            <option value="playfair-display">Playfair Display</option>

            <option value="merriweather">Merriweather</option>
            <option value="ubuntu">Ubuntu</option>
            <option value="lobster">Lobster</option>
            <option value="dosis">Dosis</option>
          </select>

          <select className="ql-size" defaultValue="medium">
            {fontSize.map((f) => (
              <option value={f} key={f}>
                {f}
              </option>
            ))}
          </select>
          <select className="ql-header" defaultValue="3">
            <option value="1">Heading 1</option>
            <option value="2">Heading 2</option>
            <option value="3">Heading 3</option>
            <option value="4">Heading 4</option>
            <option value="5">Heading 5</option>
            <option value="6">Heading 6</option>
          </select>
          <select className="ql-lineheight">
            <option value="1.0">1</option>
            <option value="1.2">1.2</option>
            <option value="1.4">1.4</option>
            <option value="1.6">1.6</option>
            <option value="1.8">1.8</option>
            <option value="2.0">2.0</option>
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="super" />
          <button className="ql-script" value="sub" />
          <button className="ql-blockquote" />
          <button className="ql-direction" />
        </span>
        <span className="ql-formats">
          <select className="ql-align" />
          <select className="ql-color">
            {colors.map((c) => (
              <option value={c} key={c}></option>
            ))}
          </select>
          <select className="ql-background">
            {colors.map((c) => (
              <option value={c} key={c}></option>
            ))}
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          <button className="ql-image" />
          <button className="ql-video" />
        </span>
        <span className="ql-formats">
          <button className="ql-formula" />
          <button className="ql-code-block" />
          <button className="ql-clean" />
        </span>
        <span className="ql-formats">
          <button className="ql-undo">
            <CustomUndo />
          </button>
          <button className="ql-redo">
            <CustomRedo />
          </button>
        </span>
        <span className="ql-formats">
          <button
            onClick={() => {
              setUpinSelect(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="18"
              viewBox="0 0 28 18"
              fill="none"
              className="upin-svg"
            >
              <path
                d="M12.9974 13.6673H7.33073C5.48628 13.6673 3.91406 13.0173 2.61406 11.7173C1.31406 10.4173 0.664062 8.8451 0.664062 7.00065C0.664062 5.15621 1.31406 3.58398 2.61406 2.28398C3.91406 0.983984 5.48628 0.333984 7.33073 0.333984H12.9974V2.33398H7.33073C6.04184 2.33398 4.94184 2.78954 4.03073 3.70065C3.11962 4.61176 2.66406 5.71176 2.66406 7.00065C2.66406 8.28954 3.11962 9.38954 4.03073 10.3007C4.94184 11.2118 6.04184 11.6673 7.33073 11.6673H12.9974V13.6673ZM8.7974 8.00065V6.00065H19.1307V8.00065H8.7974ZM27.3307 7.00065H25.3307C25.3307 5.71176 24.8752 4.61176 23.9641 3.70065C23.0529 2.78954 21.9529 2.33398 20.6641 2.33398H14.9974V0.333984H20.6641C22.5085 0.333984 24.0807 0.983984 25.3807 2.28398C26.6807 3.58398 27.3307 5.15621 27.3307 7.00065ZM21.2974 17.6673V13.6673H17.2974V11.6673H21.2974V7.66732H23.2974V11.6673H27.2974V13.6673H23.2974V17.6673H21.2974Z"
                fill="#333333"
              />
            </svg>
          </button>
        </span>
        <span className="ql-formats">
          <span className="ql-search">
            <SearchComponent click={toggleSearch} />
          </span>
          <div
            id="search-container"
            style={{ display: searchModal ? "" : "none" }}
            className="search-container"
          >
            <div className="h5 d-flex flex-row ">
              {" "}
              Replace Sentence{" "}
              <div className="ms-auto" onClick={toggleSearch}>
                {" "}
                <BsX />{" "}
              </div>{" "}
            </div>
            <input
              id="search-input"
              className="form-control"
              type="text"
              placeholder="search"
            />
            <input
              id="replace-input"
              className="form-control"
              type="text"
              placeholder="replace"
            />
            <div className="search-btn-container">
              <Button id="search" size="sm" className="find-replace-btn">
                find
              </Button>
              <Button id="replace" size="sm" className="find-replace-btn">
                replace
              </Button>
              <button id="replace-all" size="sm" className="find-replace-btn">
                replace all
              </button>
            </div>
          </div>
        </span>
      </div>
      <UpinSelectModal
        isOpen={upinSelect}
        toggle={() => {
          setUpinSelect(false);
        }}
        handleAdd={(id) => {
          if (id) {
            const value = quill.getSelection();
            quill.formatText(value, "upin", { id: id });
            setUpinSelect(false);
          } else toast.error("Please select upin!");
        }}
      />
    </>
  );
};

export default EditorHeader;
