import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, ModalBody } from "react-bootstrap";
import { CustomButton } from "../Button/CustomButton";
import TextFieldComponent from "../TextFieldComponent/TextField";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { updatePageApi } from "../../api/api";
import usePageDetailsData, {
  useCurrentPage,
  usePageIndexData,
  usePageUpdateId,
} from "../../store/pageStore";
import { getWordCount } from "../../constant";

const ChangePageModa = ({ isOpen, toggle }) => {
  const [pageCurrentData] = useCurrentPage();
  const [updateId] = usePageUpdateId();

  const [loader, setLoader] = useState(false);
  const [pages, setPage] = usePageDetailsData();
  const [pageIndex, setPageIndex] = usePageIndexData();

  const schema = useMemo(
    () =>
      yup.object().shape({
        "Page Number": yup
          .number()
          .positive()
          .min(1)
          .max(pages?.length || 0)
          .required(),
      }),
    [pages]
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    setValue("Page Number", (pageIndex || 0) + 1);
  }, [pageIndex]);
  const getPageAndSetPage = async (pn) => {
    if (
      pages &&
      updateId &&
      pageCurrentData !== pages[pageIndex]?.pageContent
    ) {
      const data = {
        pageContent: pageCurrentData,
        pageID: updateId,
        wordCount: getWordCount(),
      };
      await updatePageApi(data);
      const pd = [...pages];
      pd[pd] = { ...pd[pageIndex], pageContent: pageCurrentData };
      setPage(pd);
    }
    setPageIndex(pn);
  };
  const addHandle = async (data) => {
    setLoader(true);
    const index = data["Page Number"] - 1;
    await getPageAndSetPage(index);
    toggle();
    setLoader(false);
  };
  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          Page{" "}
          <span>
            {(pageIndex || 0) + 1} of {pages?.length}
          </span>
        </Modal.Title>
      </Modal.Header>

      <ModalBody className="d-flex flex-column gap-3">
        <TextFieldComponent
          placeholder="Page Number"
          label="Page Number"
          error={errors?.["Page Number"]?.message}
          other={{
            ...register("Page Number"),
          }}
        />
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={`Change Page`}
            onClick={handleSubmit(addHandle)}
            loading={loader}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePageModa;
