import { Quill } from "react-quill";
import { upinStore } from "./store";

const Inline = Quill.import("blots/inline");

window.displayUpin = function (e, data) {
  upinStore.showUpin({ event: e, id: data, show: true });
};
class UPIN extends Inline {
  static create(value) {
    if (!value) return super.crate(false);
    let node = super.create(value);
    node.classList.add("ql-upin");
    node.setAttribute("id", `${value?.id}`);
    node.setAttribute("onclick", `displayUpin(event,"${value?.id}")`);
    return node;
  }
  static formats(domNode) {
    if (domNode.getAttribute("id")) {
      return {
        id: domNode.getAttribute("id"),
      };
    } else {
      return super.formats(domNode);
    }
  }
  formats() {
    // Returns the formats list this class (this format).
    let formats = super.formats();
    // Inline has the domNode reference, which in this
    // case represents the SPAN, result defined in tagName.
    formats["upin"] = UPIN.formats(this.domNode);
    // In the code above, it is as if we are adding this new format.
    return formats;
  }
}

UPIN.blotName = "upin";
UPIN.className = "ql-upin";
UPIN.tagName = "SPAN";
export default UPIN;
