import Dropzone from "react-dropzone";
import { toast } from "react-hot-toast";

const CustomDropzone = ({
  handleDrop,
  isMulti,
  maxSize,
  accept,
  component,
  textComponent,
}) => {
  return (
    <Dropzone
      multiple={isMulti}
      maxSize={maxSize}
      onDrop={(files) => handleDrop(files)}
      accept={accept}
      onDropRejected={(e) =>
        toast.error(
          e && e[0] && e[0].errors && e[0].errors[0] && e[0].errors[0].code
            ? e[0].errors[0].code
            : "Please Check Your File"
        )
      }
      useFsAccessApi={false}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={"dropContainer"}>
          <div
            {...getRootProps({
              className: "dropzone",
            })}
          >
            {component}

            <input {...getInputProps()} />
            <p className={"textContainer"}>{textComponent}</p>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default CustomDropzone;
