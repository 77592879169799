import { useCallback, useEffect, useRef, useState } from "react";

export const useApi = ({ fun, trigger }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const funRef = useRef(fun);
  const lisitnResponse = useCallback((data) => {
    if (trigger) trigger(data);
  }, []);
  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      const [data, err] = await funRef.current();
      if (err) setError(err);
      if (data) {
        lisitnResponse(data);
        setData(data);
      }
      setLoading(false);
    };
    if (funRef.current) callApi();
  }, [funRef]);
  return { isLoading: loading, data: data, error: error };
};
