import Main from "./Main";
import EditorContextProvider from "./context/EditorContext";
import { Toaster } from "react-hot-toast";
const App = () => {
  return (
    <>
      <EditorContextProvider>
        <Main />
      </EditorContextProvider>
      <Toaster position="top-center" reverseOrder={false} gutter={8} />
    </>
  );
};

export default App;
