import { MdClose, MdDateRange, MdOutlineAddTask } from "react-icons/md";
import { BsPin } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
const AddTaskSidebar = () => {
  return (
    <div className="add-task-sidebar">
      <div className="sidebar-content">
        <div className="heading d-flex justify-content-between align-items-center">
          <div>
            <p className="fs-14 light-grey">MAKE</p>
            <p className="fs-20 light-grey fw-500">Notes</p>
          </div>
          <div className="d-flex align-items-center">
            <a href="#" className="light-grey fs-20">
              <MdClose />
            </a>
          </div>
        </div>
        <div className="sidebar-body">
          <a href="#" className="add-note">
            <MdOutlineAddTask />
            Add a note
          </a>

          <div className="add-note-box">
            <div className="radio-button-group checkbox-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                ></input>
                <div className="form-check-label" htmlFor="flexRadioDefault1">
                  <div className="add-note-heading">
                    <p>Title</p>
                    <div className="action">
                      <a href="#">
                        <RiDeleteBin6Line />
                      </a>
                      <a href="#">
                        <BsPin />
                      </a>
                    </div>
                  </div>
                  <textarea
                    className="input-box"
                    placeholder="Enter note..."
                  ></textarea>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="fs-16 light-grey d-flex align-items-center">
                      <MdDateRange className="fs-18 me-2" />
                      Date/Time
                    </p>
                    <a href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="12"
                        viewBox="0 0 20 12"
                        fill="none"
                      >
                        <path
                          d="M16.0337 11.4364L5.05879 11.4512L6.32569 10.1843L16.0484 10.1843V2.93643L14.0155 4.96936L13.1316 4.08548L16.6671 0.549948L20.2027 4.08548L19.3188 4.96936L17.2858 2.93643V10.1843C17.2858 10.5182 17.1606 10.8104 16.9102 11.0608C16.6598 11.3112 16.3676 11.4364 16.0337 11.4364ZM3.29102 11.4512L-0.244514 7.91564L0.63937 7.03176L2.6723 9.06469L2.6723 1.81685C2.6723 1.48293 2.79752 1.19076 3.04795 0.940328C3.29839 0.689895 3.59056 0.564678 3.92447 0.564678L14.8994 0.549948L13.6325 1.81685L3.90974 1.81685V9.06469L5.94267 7.03176L6.82655 7.91564L3.29102 11.4512Z"
                          fill="#666666"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className="btn-wrap">
                    <a href="#">Done</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="added-note-list">
            <h4>Pending Tasks</h4>
            <ul>
              <li>
                <div className="add-note-box">
                  <div className="radio-button-group checkbox-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      ></input>
                      <div
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        <div className="add-note-heading">
                          <p>Title</p>
                          <div className="action">
                            <a href="#">
                              <RiDeleteBin6Line />
                            </a>
                            <a href="#">
                              <BsPin />
                            </a>
                          </div>
                        </div>
                        <h6 className="desc">
                          Lorem ipsum nisi ut aliquip ex commodo consequat. Duis
                          aute irure Lorem ipsum nisi ut aliquip ex commodo
                          consequat. Duis aute irure
                        </h6>
                        <div className="task-due-date d-flex justify-content-between align-items-center">
                          <p className="label">Tomorrow</p>
                          <a href="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="12"
                              viewBox="0 0 20 12"
                              fill="none"
                            >
                              <path
                                d="M16.0337 11.4364L5.05879 11.4512L6.32569 10.1843L16.0484 10.1843V2.93643L14.0155 4.96936L13.1316 4.08548L16.6671 0.549948L20.2027 4.08548L19.3188 4.96936L17.2858 2.93643V10.1843C17.2858 10.5182 17.1606 10.8104 16.9102 11.0608C16.6598 11.3112 16.3676 11.4364 16.0337 11.4364ZM3.29102 11.4512L-0.244514 7.91564L0.63937 7.03176L2.6723 9.06469L2.6723 1.81685C2.6723 1.48293 2.79752 1.19076 3.04795 0.940328C3.29839 0.689895 3.59056 0.564678 3.92447 0.564678L14.8994 0.549948L13.6325 1.81685L3.90974 1.81685V9.06469L5.94267 7.03176L6.82655 7.91564L3.29102 11.4512Z"
                                fill="#666666"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="add-note-box">
                  <div className="radio-button-group checkbox-group">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      ></input>
                      <div
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        <div className="add-note-heading">
                          <p>Title</p>
                          <div className="action">
                            <a href="#">
                              <RiDeleteBin6Line />
                            </a>
                            <a href="#">
                              <BsPin />
                            </a>
                          </div>
                        </div>
                        <h6 className="desc">
                          Lorem ipsum nisi ut aliquip ex commodo consequat. Duis
                          aute irure Lorem ipsum nisi ut aliquip ex commodo
                          consequat. Duis aute irure
                        </h6>
                        <div className="task-due-date d-flex justify-content-between align-items-center">
                          <p className="label">Mon, 03 Oct</p>
                          <a href="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="12"
                              viewBox="0 0 20 12"
                              fill="none"
                            >
                              <path
                                d="M16.0337 11.4364L5.05879 11.4512L6.32569 10.1843L16.0484 10.1843V2.93643L14.0155 4.96936L13.1316 4.08548L16.6671 0.549948L20.2027 4.08548L19.3188 4.96936L17.2858 2.93643V10.1843C17.2858 10.5182 17.1606 10.8104 16.9102 11.0608C16.6598 11.3112 16.3676 11.4364 16.0337 11.4364ZM3.29102 11.4512L-0.244514 7.91564L0.63937 7.03176L2.6723 9.06469L2.6723 1.81685C2.6723 1.48293 2.79752 1.19076 3.04795 0.940328C3.29839 0.689895 3.59056 0.564678 3.92447 0.564678L14.8994 0.549948L13.6325 1.81685L3.90974 1.81685V9.06469L5.94267 7.03176L6.82655 7.91564L3.29102 11.4512Z"
                                fill="#666666"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTaskSidebar;
