import { Tab } from "react-bootstrap";
import AddChapterModal from "../../../ui/modals/AddChapterModal";
import { useState } from "react";
import useChapterDetailsData, {
  useChapeterIndexData,
} from "../../../store/chapterStore";
import DeleteConfirmModal from "../../../ui/modals/DeleteConfirmModal";
import { deleteChapterApi, getPagesApi } from "../../../api/api";
import usePageDetailsData, { usePageIndexData } from "../../../store/pageStore";
import { useParams } from "react-router-dom";

const ChapterTab = () => {
  const [toggle, setToggle] = useState(false);
  const [delToggle, setDelToggle] = useState(false);
  const [ch, setCh] = useChapterDetailsData();
  const [cdata, setCdata] = useState(null);
  const [chIndex, setChIndex] = useChapeterIndexData();
  const [, setPageIndex] = usePageIndexData();
  const [, setPage] = usePageDetailsData();
  const [loader, setLoader] = useState(false);
  const id = useParams().id;

  const getPageAndSetPage = async (chid) => {
    const [data] = await getPagesApi({ bookID: id, chapterID: chid });
    setPage(data?.bookContent?.bookPages || []);
  };
  const deleteCh = async () => {
    setLoader(true);
    const [, err] = await deleteChapterApi({ chapterID: cdata?._id });
    if (!err) {
      const cd = ch.filter((d) => d._id !== cdata._id);
      setPageIndex(0);
      if (!cd?.length) {
        setChIndex(0);
        setPage([]);
      } else if (cd[chIndex]) {
        await getPageAndSetPage(cd[chIndex]?._id);
      } else if (ch[chIndex - 1]) {
        setChIndex(chIndex - 1);
        await getPageAndSetPage(cd[chIndex - 1]?._id);
      } else {
        setPage([]);
      }
      setCh(cd);
    }
    setCdata(null);
    setDelToggle(false);
    setLoader(false);
  };
  return (
    <>
      <Tab.Pane eventKey="second">
        <div className="add-chapter-page-content">
          <div className="btn-wrap" onClick={() => setToggle(true)}>
            <button className="btn btn-green">Add Chapter</button>
          </div>
          <ul>
            {ch?.length ? (
              ch.map((c) => (
                <li key={c._id}>
                  <h6>{c.chapterName}</h6>
                  <span>
                    ({c?.totalPage || 0} pages & {c?.wordCount || 0} words)
                  </span>
                  <div className="btn-wrap">
                    <button
                      className="btn btn-remove"
                      onClick={() => {
                        setCdata(c);
                        setDelToggle(true);
                      }}
                    >
                      Remove
                    </button>
                    <button
                      className="btn btn-edit"
                      onClick={() => {
                        setCdata(c);

                        setToggle(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <li>
                <h6>No Chapter Found!</h6>
              </li>
            )}
          </ul>
        </div>
      </Tab.Pane>
      <AddChapterModal
        isOpen={toggle}
        toggle={() => {
          setToggle(false);
          setCdata(null);
        }}
        cdata={cdata}
      />
      <DeleteConfirmModal
        isOpen={delToggle}
        title="Chapter"
        text={`Are you sure you want to delete ${cdata?.chapterName} chapter? Inside chapter all pages will automatically removed!`}
        toggle={() => {
          setCdata(null);
          setDelToggle(false);
        }}
        onDelete={deleteCh}
        loader={loader}
      />
    </>
  );
};

export default ChapterTab;
