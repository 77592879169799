import { createContext, useContext, useState } from "react";

export const EditorContext = createContext({
  quill: null,
  setEditorContext: () => {},
});
export const useEditorContext = () => {
  const quillContext = useContext(EditorContext);
  return quillContext;
};
const EditorContextProvider = (props) => {
  const [quill, setQuill] = useState(null);

  return (
    <EditorContext.Provider
      value={{
        quill: quill,
        setEditorContext: (context) => setQuill(context),
      }}
    >
      {props.children}
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
