import { Dropdown, Tab } from "react-bootstrap";
import { BsThreeDotsVertical, BsUpload } from "react-icons/bs";
import useAssetData from "../../../store/assetStore";
import { getAssestsUrl } from "../../../helper/getAssestsUrl";
import moment from "moment/moment";
import { deleteAssetsApi, uploadAssetApi } from "../../../api/api";
import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { successMsg } from "../../../constant";

const choiceViaType = (type, url) => {
  switch (type) {
    case 1:
      return <img src={getAssestsUrl(url)} alt={url} />;

    case 2:
      return (
        <audio
          controls
          controlsList="noplaybackrate nodownload"
          style={{ maxWidth: "270px", marginTop: "6px", marginLeft: "2px" }}
        >
          <source src={getAssestsUrl(url)} />
          Your browser does not support the audio element.
        </audio>
      );
    case 3:
      return (
        <video controls style={{ maxWidth: "277px" }}>
          <source src={getAssestsUrl(url)} />
          Your browser does not support HTML video.
        </video>
      );
    default:
      return null;
  }
};

const ImageTab = () => {
  const inputFile = useRef(null);
  const [filesLoading, setFilesLoading] = useState(false);

  const [assestsData, setAssestsData] = useAssetData();
  const deleteAssests = async (id) => {
    const [error] = await deleteAssetsApi({ assetId: id });

    if (!error) {
      toast.success(successMsg.IMAGE_DELETE);
      const findIndex = assestsData.findIndex((a) => a._id === id);
      if (findIndex !== -1) {
        const newData = assestsData.filter((a) => a._id !== id);
        setAssestsData(newData);
      }
    }
  };
  const handleFileUpload = async (e) => {
    const { files } = e.target;
    if (files?.[0]) {
      setFilesLoading(true);
      const fileData = new FormData();
      fileData.append("authorID", localStorage.getItem("authorID"));
      fileData.append("assetUrl", files[0]);
      const [data, error] = await uploadAssetApi(fileData);
      if (!error && data?.responseData?.assetUrl) {
        setAssestsData([...assestsData, data.responseData]);
        toast.success(successMsg.IMAGE_UPLOAD);
      }
      setFilesLoading(false);
    }
  };
  const onButtonClick = () => {
    inputFile.current.click();
  };
  return (
    <Tab.Pane eventKey="fourth">
      <div className="add-asset-tab">
        <div className="btn-wrap">
          <button className="btn btn-green" onClick={onButtonClick}>
            <BsUpload className="me-2" />
            {filesLoading ? "Loading.." : "Upload Assets"}
          </button>
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </div>
        <ul>
          {assestsData && assestsData.length ? (
            assestsData.map(
              (a) =>
                a.type && (
                  <li key={a._id}>
                    {choiceViaType(a.type, a.assetUrl)}
                    <div className="asset-details d-flex justify-content-between">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>
                          Uploaded{" "}
                          <span>{moment(a.createdAt).fromNow(true)}</span> ago
                        </h6>
                      </div>
                      <Dropdown className="without-background" align="end">
                        <Dropdown.Toggle id="dropdown-basic">
                          <BsThreeDotsVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => deleteAssests(a._id)}>
                            Delete
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              window.open(getAssestsUrl(a.assetUrl))
                            }
                          >
                            Download
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                )
            )
          ) : (
            <li>
              <h6 className="mb-4">No Assest's Found!</h6>
            </li>
          )}
        </ul>
      </div>
    </Tab.Pane>
  );
};

export default ImageTab;
