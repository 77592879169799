// This is an example of a third-party store
// that you might need to integrate with React.

// If your app is fully built with React,
// we recommend using React state instead.

let upinData = null;
let listeners = [];

export const upinStore = {
  showUpin(data) {
    upinData = data;
    emitChange();
  },
  subscribe(listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return upinData;
  },
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}
