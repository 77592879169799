// This is an example of a third-party store
// that you might need to integrate with React.

// If your app is fully built with React,
// we recommend using React state instead.

let isLoading = false;
let listeners = [];

export const loaderStore = {
  setLoader(data) {
    isLoading = data;
    emitChange();
  },
  subscribe(listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return isLoading;
  },
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}
