import axios from "axios";
import toast from "react-hot-toast";

const reqUse = async (config) => {
  config.headers = config.headers || {};

  config.headers["token"] = localStorage.getItem("token");

  return config;
};
const resUse = (response) =>
  new Promise((resolve) => {
    resolve(response);
  });
const resUseError = (error) => {
  if (error?.response?.data?.message) {
    toast.error(error.response.data.message);
  } else if (error?.message) {
    toast.error(error.message);
  }
  if (error?.response?.status === 401) {
    localStorage.clear();
    window.location.reload();
  }
  return new Promise((_, reject) => {
    reject({ ...error, error: true });
  });
};

const authorBaseURL = process.env.REACT_APP_BASE_URL1;
const bookBaseURL = process.env.REACT_APP_BASE_URL2;

const axiosInstanceBook = axios.create({
  baseURL: bookBaseURL,
});

axiosInstanceBook.interceptors.request.use(reqUse);

axiosInstanceBook.interceptors.response.use(resUse, resUseError);

const axiosInstanceAuthor = axios.create({
  baseURL: authorBaseURL,
});

axiosInstanceAuthor.interceptors.request.use(reqUse);

axiosInstanceAuthor.interceptors.response.use(resUse, resUseError);

export { axiosInstanceAuthor, axiosInstanceBook };
