import { toast } from "react-hot-toast";

export const asyncWrapper = async (asyncFunction, ...params) => {
  const url = params[0],
    data = params[1],
    config = params[3];
  try {
    const apiData = await asyncFunction(url, data, config);
    if (!apiData?.data?.success && apiData?.data.errorCode) {
      toast.error(apiData?.data.errorCode);
    }
    return grabData(apiData?.data);
  } catch (error) {
    return [null, error];
  }
};
export const grabData = (data) =>
  (data && (data?.success || data?.suceess) && [data, null]) || [null, data];
