import { Quill } from "react-quill";

const Inline = Quill.import("blots/inline");
const picklineheight = {
  true: 1,
  1.0: 1,
  1.2: 2,
  1.4: "3",
  1.6: "4",
  1.8: "5",
  "2.0": "6",
};
class LineHeight extends Inline {
  static create(value) {
    let node = super.create(value);
    node.classList.add(`ql-lineheight-${picklineheight[value]}`);
    return node;
  }

  formats() {
    // Returns the formats list this class (this format).
    let formats = super.formats();
    // Inline has the domNode reference, which in this
    // case represents the SPAN, result defined in tagName.
    formats["lineheight"] = LineHeight.formats(this.domNode);
    // In the code above, it is as if we are adding this new format.
    return formats;
  }
}

LineHeight.blotName = "lineheight";
LineHeight.className = "ql-lineheight";
LineHeight.tagName = "SPAN";
export default LineHeight;
