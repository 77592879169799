import { Accordion, Card, Tab } from "react-bootstrap";
import UpinModal from "../../../ui/modals/upin/UpinModal";
import { useState } from "react";
import { TbEdit } from "react-icons/tb";
import { useUpinDetailsData } from "../../../store/upinStore";
import { getAssestsUrl } from "../../../helper/getAssestsUrl";
import { MdDelete } from "react-icons/md";
import DeleteConfirmModal from "../../../ui/modals/DeleteConfirmModal";
import { deleteUpinApi } from "../../../api/api";
import { toast } from "react-hot-toast";
const UpinTab = () => {
  const [upinModal, setUpinModal] = useState(false);
  const [upinData, setUpinData] = useState(null);
  const [upins, setUpins] = useUpinDetailsData();
  const [loading, setLoading] = useState(false);
  const [deleteToggle, setDelToggle] = useState(false);
  const [udata, setUData] = useState(null);
  const deleteUpin = async () => {
    setLoading(true);
    setDelToggle(false);
    if (udata?._id) {
      const [, err] = await deleteUpinApi({
        upinID: udata._id,
      });
      if (!err) {
        const cd = upins.filter((d) => d._id !== udata._id);
        setUpins(cd);
        toast.success("Upin deleted!");
      }
    }
    setUData(null);
    setLoading(false);
  };
  return (
    <>
      <Tab.Pane eventKey="fifth">
        <div className="add-upin-tab">
          <div className="btn-wrap" onClick={() => setUpinModal(true)}>
            <button className="btn btn-green">Add upin</button>
          </div>
          <p className="primary-color fw-500">Added U-pin Links</p>
          <span className="light-grey fs-14">
            you can manage all your U-pin links here
          </span>
          <Accordion defaultActiveKey="0" className="mt-4">
            <ul>
              {upins?.length ? (
                upins.map((a, idx) => (
                  <Card key={a._id} className="mb-2">
                    <Accordion.Item eventKey={idx} className="px-2 py-1 pt-2">
                      <Accordion.Header>
                        {a.title}{" "}
                        <TbEdit
                          size={24}
                          className="ms-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setUpinData(a);
                            setUpinModal(true);
                          }}
                        />
                        <MdDelete
                          size={24}
                          className="ms-2 text-danger"
                          onClick={(e) => {
                            e.stopPropagation();
                            setUData(a);
                            setDelToggle(true);
                          }}
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        {a.img ? (
                          <Card.Img variant="top" src={getAssestsUrl(a.img)} />
                        ) : null}
                        {a.uvideo ? (
                          <iframe
                            title="video"
                            width="100%"
                            height="200"
                            src={a.uvideo}
                            allowFullScreen
                            className="mb-2"
                          />
                        ) : null}
                        {a.video ? (
                          <video width="100%" controls className="mb-2">
                            <source src={getAssestsUrl(a.video)} />
                            Your browser does not support HTML video.
                          </video>
                        ) : null}
                        {a?.audio ? (
                          <div className="d-flex justify-content-center">
                            <audio
                              controls
                              controlsList="noplaybackrate nodownload"
                              className="mb-2"
                            >
                              <source src={getAssestsUrl(a.audio)} />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        ) : null}
                        {a.title ? (
                          <Card.Title className="h5 mt-1">{a.title}</Card.Title>
                        ) : null}
                        {a.description ? (
                          <Card.Text
                            style={{ fontWeight: 300, fontSize: "14px" }}
                          >
                            {a.description}
                          </Card.Text>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                ))
              ) : (
                <li>
                  <h6 className="mb-4">No Upin's Found!</h6>
                </li>
              )}
            </ul>
          </Accordion>
        </div>
      </Tab.Pane>
      <UpinModal
        isOpen={upinModal}
        toggle={() => {
          setUpinModal(!upinModal);
        }}
        close={() => {
          setUpinData(null);
          setUpinModal(false);
        }}
        pindata={upinData}
      />
      <DeleteConfirmModal
        title="Upin"
        text="Are you sure you want to delete this upin? After delete you need to manually remove from page."
        toggle={() => {
          setUData(null);
          setDelToggle(false);
        }}
        onDelete={deleteUpin}
        loader={loading}
        isOpen={deleteToggle}
      />
    </>
  );
};

export default UpinTab;
