import { Button } from "react-bootstrap";

export const CustomButton = ({
  onClick,
  loading,
  text,
  classReplace = "",
  disabled = false,
  style = {},
}) => {
  return (
    <Button
      size="sm"
      className={classReplace || "find-replace-btn"}
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading ? "Loading.." : text}
    </Button>
  );
};
