import { MdClose } from "react-icons/md";
import { BsPin, BsPlusLg, BsSearch } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
const AddNoteSidebar = () => {
  return (
    <div className="add-note-sidebar">
      <div className="sidebar-content">
        <div className="heading d-flex justify-content-between align-items-center">
          <div>
            <p className="fs-14 light-grey">MAKE</p>
            <p className="fs-20 light-grey fw-500">Notes</p>
          </div>
          <div className="d-flex align-items-center">
            <a href="#" className="light-grey fs-16">
              <BsSearch />
            </a>
            <a href="#" className="light-grey fs-20">
              <MdClose />
            </a>
          </div>
        </div>
        <div className="sidebar-body">
          <a href="#" className="add-note">
            <BsPlusLg />
            Add a note
          </a>
          <div className="add-note-box">
            <div className="add-note-heading">
              <p>Title</p>
              <div className="action">
                <a href="#">
                  <RiDeleteBin6Line />
                </a>
                <a href="#">
                  <BsPin />
                </a>
              </div>
            </div>
            <textarea
              className="input-box"
              placeholder="Enter note..."
            ></textarea>
            <div className="btn-wrap">
              <a href="#">Done</a>
            </div>
          </div>
          <div className="added-note-list">
            <h4>Added Notes</h4>
            <ul>
              <li>
                <div className="add-note-box">
                  <div className="add-note-heading">
                    <p>Title</p>
                    <div className="action">
                      <a href="#">
                        <RiDeleteBin6Line />
                      </a>
                      <a href="#">
                        <BsPin />
                      </a>
                    </div>
                  </div>
                  <h6 className="desc">
                    Lorem ipsum nisi ut aliquip ex commodo consequat. Duis aute
                    irure Lorem ipsum nisi ut aliquip ex commodo consequat. Duis
                    aute irure
                  </h6>
                </div>
              </li>
              <li>
                <div className="add-note-box">
                  <div className="add-note-heading">
                    <p>Title</p>
                    <div className="action">
                      <a href="#">
                        <RiDeleteBin6Line />
                      </a>
                      <a href="#">
                        <BsPin />
                      </a>
                    </div>
                  </div>
                  <h6 className="desc">
                    Lorem ipsum nisi ut aliquip ex commodo consequat. Duis aute
                    irure Lorem ipsum nisi ut aliquip ex commodo consequat. Duis
                    aute irure
                  </h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNoteSidebar;
