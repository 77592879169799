import "quill/dist/quill.snow.css";
import "./ql-custom.scss";

import ReactQuill, { Quill } from "react-quill";
import Searcher from "../../modules/findreplace/Searcher";
import Track from "../../modules/findreplace/SearchBlot";

import { useEffect, useRef, useSyncExternalStore } from "react";
import { useEditorContext } from "../../context/EditorContext";
import Counter from "../../modules/wordcount/wordcount";
import UPIN from "../../modules/upin/UPinBlot";
import LineHeight from "../../modules/line-height/LineHeight";
import usePageDetailsData, {
  useCurrentPage,
  usePageIndexData,
} from "../../store/pageStore";
import Upincard from "./upincard";

import { uploadAssetApi } from "../../api/api";
import { fontSize, maxPageHeight, maxSizes } from "../../constant";
import { toast } from "react-hot-toast";
import ImageResize from "quill-image-resize-with-replace-module-react";
import { loaderStore } from "../../store/loaderStore";
import Spinner from "../../ui/loader/Loader";
var Delta = Quill.import("delta");

//counter modules
Quill.register("modules/counter", Counter);

//find and replace module
Quill.register("modules/Searcher", Searcher);
Quill.register("formats/SearchedString", Track);

//for line height
Quill.register("formats/lineheight", LineHeight);

const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSize;
Quill.register(Size, true);

//for upin
Quill.register("formats/upin", UPIN);

//image resize
Quill.register("modules/imageResize", ImageResize);

// Add sizes to whitelist and register them
// const Size = Quill.import("formats/size");
// Size.whitelist = ["extra-small", "small", "medium", "large"];
// Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
  "verdana",
  "tahoma",
  "roboto",
  "lato",
  "poppins",
  "raleway",
  "oswald",
  "nunito",
  "playfair-display",
  "merriweather",
  "ubuntu",
  "lobster",
  "dosis",
];
Quill.register(Font, true);

var BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Formats objects for setting up the Quill editor
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
  "SearchedString",
  "upin",
  "lineheight",
  "width",
  "style",
  "onclick",
  "id",
];
// function showColor(value) {
//   if (value === "color-picker") {
//     value = prompt("Enter Hex/RGB/RGBA");
//   }
//   this.quill.format("color", value);
// if (value === "color-picker") {
//   var picker = document.getElementById("color-picker");
//   if (!picker) {
//     picker = document.createElement("input");
//     picker.id = "color-picker";
//     picker.type = "color";
//     picker.style.display = "none";
//     picker.value = "#FF0000";
//     document.body.appendChild(picker);
//     picker.addEventListener(
//       "change",
//       () => {
//         this.quill.format("color", picker.value);
//       },
//       false
//     );
//   }
//   picker.click();
// } else {
//   this.quill.format("color", value);
// }
// }
// function showBgColor(value) {
//   if (value === "color-picker") {
//     value = prompt("Enter Hex/RGB/RGBA");
//   }
//   this.quill.format("background", value);
// }
async function imageHandler() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();
  const quill = this.quill;
  input.onchange = async () => {
    const url = await uploadImageAndShareUrl(input.files[0]);
    if (url) {
      let range = quill.getSelection(true);
      this.quill.updateContents(
        new Delta()
          .retain(range.index)
          .delete(range.length)
          .insert({ image: url }),
        Quill.sources.USER
      );
    }
  };
}
const uploadImageAndShareUrl = async (file) => {
  try {
    loaderStore.setLoader(true);
    if (!file || !file.size) {
      toast.error("Please select an valid image!");
      return false;
    }
    if (file.size > maxSizes.IMAGE) {
      toast.error("Image is to big! upload size is less then 2mb!");
      return false;
    }
    var formData = new FormData();
    formData.append("assetUrl", file);
    formData.append("authorID", localStorage.getItem("authorID"));
    const [data, error] = await uploadAssetApi(formData);
    if (!error && data?.responseData?.assetUrl) {
      return process.env.REACT_APP_BASE_AWS + data?.responseData?.assetUrl;
    } else {
      toast.error(error?.message || "Image upload failed!");
      return "";
    }
  } catch (error) {
  } finally {
    loaderStore.setLoader(false);
  }
};
const modules = {
  Searcher: true,
  counter: {
    container: "#counter",
    unit: "word",
  },
  toolbar: {
    container: "#qill-custom-toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      // color: showColor,
      // background: showBgColor,
      lineheight: function (val) {
        this.quill.format("lineheight", val);
      },
      image: imageHandler,
    },
  },
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
    displaySize: true,
    handleClick: (img) => {
      if (img) {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
        input.onchange = async () => {
          const url = await uploadImageAndShareUrl(input.files[0]);
          if (url) {
            img.src = url;
          }
        };
      }
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
  },
};

const Editor = () => {
  const imageLoader = useSyncExternalStore(
    loaderStore.subscribe,
    loaderStore.getSnapshot
  );
  const [pages] = usePageDetailsData();
  const [pgd, setPgd] = useCurrentPage();
  const [pageIndex] = usePageIndexData();

  const qillRef = useRef();
  const qill = qillRef.current?.editor;
  const setQContext = useEditorContext().setEditorContext;

  useEffect(() => {
    window.Quill = Quill;
    setQContext(qill);
  }, [qill, setQContext]);
  useEffect(() => {
    if (!qill) return;
    if (pages && pages[pageIndex]) {
      setPgd(pages[pageIndex]?.pageContent || "");
      qill.enable();
      qill.history.clear();
    } else {
      qill.setText("Please add page!");
      qill.disable();
    }
  }, [qill, pages, pageIndex, setPgd]);
  return (
    <>
      <div className="d-flex flex-column position-relative" id="ql-outerdiv">
        <div className="quill-container" id="quill-editior">
          <ReactQuill
            ref={qillRef}
            modules={modules}
            formats={formats}
            bounds={"#root"}
            value={pgd}
            onChange={(d) => {
              const contentHeight = qill?.root?.scrollHeight || 0;
              if (contentHeight > maxPageHeight) {
                // Disable further editing
                // Optionally, you can display a warning message to the user
                qill.root.innerHTML = pgd;
                toast.error(
                  "Maximum page height reached. Further editing is not allowed."
                );
              } else {
                setPgd(d);
              }
            }}
          />
        </div>
        <Upincard Quill={Quill} qill={qill} />
      </div>
      {imageLoader ? <Spinner /> : null}
    </>
  );
};

export default Editor;
