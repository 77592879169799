import { useState } from "react";
import AddChapterModal from "../../ui/modals/AddChapterModal";
import usePageDetailsData, {
  useCurrentPage,
  usePageIndexData,
  usePageUpdateId,
} from "../../store/pageStore";
import useChapterDetailsData, {
  useChapeterIndexData,
} from "../../store/chapterStore";
import useBookData from "../../store/bookStore";
import ChangeChapter from "../../ui/modals/ChangeChapter";
import ChangePageModa from "../../ui/modals/ChangePageModa";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import { getWordCount } from "../../constant";
import { deletePageApi, updatePageApi } from "../../api/api";
import DeleteConfirmModal from "../../ui/modals/DeleteConfirmModal";
import Spinner from "../../ui/loader/Loader";
import { CustomButton } from "../../ui/Button/CustomButton";
const styleLink = { color: "purple", cursor: "pointer" };
const EditorFooter = () => {
  const [toggle, setToggle] = useState(false);
  const [openCh, setOpenCh] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  const [pgd] = useCurrentPage();

  const [bookd] = useBookData();
  const [updateId] = usePageUpdateId();

  const [pg, setPage] = usePageDetailsData();
  const [cpg, setPageIndex] = usePageIndexData();

  const [ch] = useChapterDetailsData();
  const [cch] = useChapeterIndexData();
  const [loading, setLoading] = useState(false);
  const [delToggle, setDelToggle] = useState(false);
  const [cdata, setCdata] = useState(null);

  const deleteCh = async () => {
    setLoading(true);
    setDelToggle(false);
    if (cdata?._id) {
      const [, err] = await deletePageApi({
        pageNumber: pg[cpg].pageNumber,
        pageID: updateId,
      });
      if (!err) {
        const cd = pg.filter((d) => d._id !== cdata._id);
        if (!cd.length) {
          setPageIndex(0);
        } else if (!cd[cpg]) {
          if (cd[cpg - 1]) {
            setPageIndex(cpg - 1);
          } else {
            setPageIndex(0);
          }
        }
        setPage(cd);
      }
    }
    setCdata(null);
    setLoading(false);
  };
  const updateAndChange = async (flag) => {
    try {
      if (updateId && pg?.[cpg]?.pageContent !== pgd) {
        setLoading(true);
        const data = {
          pageContent: pgd,
          wordCount: getWordCount(),
          pageID: updateId,
          pageNumber: pg[cpg].pageNumber,
        };
        await updatePageApi(data);
        const pd = [...pg];
        pd[cpg] = { ...pd[cpg], pageContent: pgd };
        setPage(pd);
      }
      if (flag && cpg > 0) setPageIndex(cpg - 1);
      if (!flag && cpg < (pg.length || 0) - 1) setPageIndex(cpg + 1);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const isPrevDisable = !cpg || cpg === 0 || cpg < 0;
  const isNextDisable =
    !pg || !pg.length || cpg === pg.length - 1 || cpg > pg.length - 1;
  const isDeleteDisable = !pg || !pg[cpg];
  return (
    <div className="ck-editor-footer">
      <div className="d-flex gap-4 align-items-center">
        {pg?.length ? (
          <div className="d-flex">
            <MdOutlineNavigateBefore
              size={28}
              role="button"
              style={{ cursor: isPrevDisable ? "not-allowed" : "pointer" }}
              onClick={() => !isPrevDisable && updateAndChange(true)}
            />
            <div
              style={styleLink}
              className="my-auto"
              onClick={() => setOpenPage(true)}
            >
              Page {(cpg || 0) + 1} of {pg.length}
            </div>
            <MdOutlineNavigateNext
              size={28}
              role="button"
              style={{ cursor: isNextDisable ? "not-allowed" : "pointer" }}
              onClick={() => !isNextDisable && updateAndChange(false)}
            />
          </div>
        ) : null}
        {bookd?.isBookChapterWise ? (
          <>
            {ch?.length ? (
              <span
                className="my-auto"
                style={styleLink}
                onClick={() => setOpenCh(true)}
              >
                Chapter {(cch || 0) + 1} of {ch?.length}
              </span>
            ) : null}
            <span
              className="my-auto"
              style={styleLink}
              onClick={() => setToggle(true)}
            >
              Add chapter
            </span>
          </>
        ) : null}
        <div>
          <CustomButton
            disabled={isDeleteDisable}
            onClick={() => {
              if (!isDeleteDisable) {
                setCdata(pg[cpg]);
                setDelToggle(true);
              }
            }}
            classReplace="btn-danger btm-small"
            text="Delete page"
          />
        </div>
        <span className="fs-14 ms-auto mr-0" id="counter"></span>
      </div>
      <AddChapterModal
        isOpen={toggle}
        toggle={() => {
          setToggle(false);
        }}
      />
      <ChangeChapter isOpen={openCh} toggle={() => setOpenCh(false)} />
      <ChangePageModa isOpen={openPage} toggle={() => setOpenPage(false)} />
      <DeleteConfirmModal
        isOpen={delToggle}
        title="Page"
        text={`Are you sure you want to delete this page?`}
        toggle={() => {
          setCdata(null);
          setDelToggle(false);
        }}
        onDelete={deleteCh}
        loader={loading}
      />
      {loading ? <Spinner /> : null}
    </div>
  );
};

export default EditorFooter;
