import { useEffect, useState } from "react";
import { Card, Col, Modal, ModalBody, Row, Tab, Tabs } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { uploadAssetApi } from "../../../api/api";
import { CustomButton } from "../../Button/CustomButton";
import { maxSizes } from "../../../constant";
import CustomDropzone from "../../dropzone/CustomDropzone";
import { RxImage } from "react-icons/rx";
import { getAssestsUrl } from "../../../helper/getAssestsUrl";
import useAssetData from "../../../store/assetStore";

const ImageModal = ({ isOpen, toggle, cdata, addHandle }) => {
  const [img, setImg] = useState();
  useEffect(() => {
    setImg(cdata);
  }, [cdata]);
  const [assestsData, setAssestsData] = useAssetData();
  const fData =
    assestsData && assestsData.length
      ? assestsData.filter((a) => a.type === 1)
      : [];
  const handleDrop = async (file) => {
    var formData = new FormData();
    formData.append("assetUrl", file[0]);
    formData.append("authorID", localStorage.getItem("authorID"));
    const [data, error] = await uploadAssetApi(formData);
    if (!error && data?.responseData?.assetUrl) {
      setImg(data?.responseData?.assetUrl);
      setAssestsData([...assestsData, data.responseData]);
    }
  };
  return (
    <Modal show={isOpen} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{cdata ? "Edit" : "Add"} Image</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <Tabs
          defaultActiveKey="Upload"
          id="controlled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Upload" title="Upload">
            {img ? (
              <div className="d-flex">
                <div className="d-flex justify-content-center">
                  <img src={getAssestsUrl(img)} alt={img} />
                </div>
                <AiFillDelete
                  size={32}
                  className="mr-0 ms-2 text-danger"
                  role="button"
                  onClick={() => setImg("")}
                />
              </div>
            ) : (
              <CustomDropzone
                isMulti={false}
                maxSize={maxSizes.IMAGE}
                handleDrop={handleDrop}
                accept={{
                  "image/png": [".png", ".jpg", ".jpeg"],
                }}
                component={
                  <div className="dropzone d-flex text-center flex-column justify-content-center">
                    <RxImage size="48" className="m-auto mb-2" />
                    Drag and drop your image file
                    <br />
                    <p className="text-secondary mt-1">
                      Upload your image file here to attach with word & sentence
                    </p>
                  </div>
                }
                textComponent=""
              />
            )}
          </Tab>
          <Tab eventKey="My Assets" title="My Assets">
            <Card
              style={{ maxHeight: "200px" }}
              className="p-2 d-flex gap-2 overflow-auto"
            >
              <Row className="m-0">
                {fData.map((a) => (
                  <Col
                    onClick={() => setImg(img === a.assetUrl ? "" : a.assetUrl)}
                    xs={6}
                    key={a._id}
                    className={`p-2`}
                    style={
                      img === a.assetUrl
                        ? {
                            border: "1px solid purple",
                          }
                        : {}
                    }
                  >
                    <img src={getAssestsUrl(a.assetUrl)} alt={a.assetUrl} />
                  </Col>
                ))}
              </Row>
            </Card>
          </Tab>
        </Tabs>
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={`${cdata ? "Edit" : "Add"} Image`}
            onClick={() => addHandle(img)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
