import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
// import imageformat1 from "../../../../src/images/image-format-1.png";
// import imageformat2 from "../../../../src/images/image-format-2.png";
// import imageformat3 from "../../../../src/images/image-format-3.png";
import { useState } from "react";
import PageTab from "./page-tab";
import ChapterTab from "./chapter-tab";
import UpinTab from "./upin-tab";
import useBookData from "../../../store/bookStore";
import ImageTab from "./image-tab";

const EditorLeftPanel = () => {
  const [bookdetails] = useBookData();
  const [eventKey, setEventKey] = useState("first");
  const changeEvent = (e) => {
    if (e === eventKey) {
      setEventKey("");
    } else {
      setEventKey(e);
    }
  };
  return (
    <div className="editor-left-panel">
      <Tab.Container id="left-tabs-example" activeKey={eventKey}>
        <div className="d-flex justify-content-between h-100">
          <div className="editor-left-panel-link">
            <Nav variant="pills" className="flex-column">
              <Nav.Item onClick={() => changeEvent("first")}>
                <Nav.Link eventKey="first">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="28"
                      viewBox="0 0 22 28"
                      fill="none"
                    >
                      <path
                        d="M10.0026 22.1993H12.0026V17.8993H16.3359V15.8993H12.0026V11.566H10.0026V15.8993H5.66927V17.8993H10.0026V22.1993ZM2.33594 27.3327C1.8026 27.3327 1.33594 27.1327 0.935937 26.7327C0.535937 26.3327 0.335938 25.866 0.335938 25.3327V2.66602C0.335938 2.13268 0.535937 1.66602 0.935937 1.26602C1.33594 0.866016 1.8026 0.666016 2.33594 0.666016H14.3693L21.6693 7.96601V25.3327C21.6693 25.866 21.4693 26.3327 21.0693 26.7327C20.6693 27.1327 20.2026 27.3327 19.6693 27.3327H2.33594ZM13.3693 8.86601V2.66602H2.33594V25.3327H19.6693V8.86601H13.3693ZM2.33594 2.66602V8.86601V2.66602V25.3327V2.66602Z"
                        fill="#333333"
                      />
                    </svg>
                  </p>
                </Nav.Link>
              </Nav.Item>
              {bookdetails?.isBookChapterWise ? (
                <Nav.Item onClick={() => changeEvent("second")}>
                  <Nav.Link eventKey="second">
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M2 24C1.46667 24 1 23.8 0.6 23.4C0.2 23 0 22.5333 0 22V2C0 1.46667 0.2 1 0.6 0.6C1 0.2 1.46667 0 2 0H15.1333V2H2V22H22V8.86667H24V22C24 22.5333 23.8 23 23.4 23.4C23 23.8 22.5333 24 22 24H2ZM6.7 18.7667V16.7667H17.3333V18.7667H6.7ZM6.7 14.5333V12.5333H17.3333V14.5333H6.7ZM6.7 10.3V8.3H17.3333V10.3H6.7ZM19.0667 7.86667V4.93333H16.1333V2.93333H19.0667V0H21.0667V2.93333H24V4.93333H21.0667V7.86667H19.0667Z"
                          fill="#333333"
                        />
                      </svg>
                    </p>
                  </Nav.Link>
                </Nav.Item>
              ) : null}{" "}
              {/* <Nav.Item onClick={() => changeEvent("third")}>
                <Nav.Link eventKey="third">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M15.6333 0.558594V2.55859H2V22.5586H22V8.95859H24V22.5586C24 23.0919 23.8 23.5586 23.4 23.9586C23 24.3586 22.5333 24.5586 22 24.5586H2C1.46667 24.5586 1 24.3586 0.6 23.9586C0.2 23.5586 0 23.0919 0 22.5586V2.55859C0 2.02526 0.2 1.55859 0.6 1.15859C1 0.758594 1.46667 0.558594 2 0.558594H15.6333ZM21.3333 0.558594V3.25859H24.0333V5.25859H21.3333V7.95859H19.3333V5.25859H16.6333V3.25859H19.3333V0.558594H21.3333ZM4 19.1586H20L15.2 12.7586L10.9667 18.3253L7.83333 14.1919L4 19.1586ZM2 2.55859V22.5586V2.55859Z"
                        fill="#333333"
                      />
                    </svg>
                  </p>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item onClick={() => changeEvent("fourth")}>
                <Nav.Link eventKey="fourth">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="22"
                      viewBox="0 0 28 22"
                      fill="none"
                    >
                      <path
                        d="M13.0031 17.6673H15.0031V10.834L17.4698 13.3007L18.8698 11.9007L14.0031 7.03398L9.13646 11.9007L10.5365 13.3007L13.0031 10.834V17.6673ZM2.70312 21.6673C2.16979 21.6673 1.70312 21.4618 1.30312 21.0507C0.903125 20.6395 0.703125 20.1784 0.703125 19.6673V2.33398C0.703125 1.82287 0.903125 1.36176 1.30312 0.950651C1.70312 0.53954 2.16979 0.333984 2.70312 0.333984H12.0365L14.0365 2.33398H25.3698C25.8809 2.33398 26.342 2.53954 26.7531 2.95065C27.1642 3.36176 27.3698 3.82287 27.3698 4.33398V19.6673C27.3698 20.1784 27.1642 20.6395 26.7531 21.0507C26.342 21.4618 25.8809 21.6673 25.3698 21.6673H2.70312ZM2.70312 2.33398V19.6673H25.3698V4.33398H13.2031L11.2031 2.33398H2.70312Z"
                        fill="#333333"
                      />
                    </svg>
                  </p>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => changeEvent("fifth")}>
                <Nav.Link eventKey="fifth">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="18"
                      viewBox="0 0 28 18"
                      fill="none"
                    >
                      <path
                        d="M12.9974 13.6673H7.33073C5.48628 13.6673 3.91406 13.0173 2.61406 11.7173C1.31406 10.4173 0.664062 8.8451 0.664062 7.00065C0.664062 5.15621 1.31406 3.58398 2.61406 2.28398C3.91406 0.983984 5.48628 0.333984 7.33073 0.333984H12.9974V2.33398H7.33073C6.04184 2.33398 4.94184 2.78954 4.03073 3.70065C3.11962 4.61176 2.66406 5.71176 2.66406 7.00065C2.66406 8.28954 3.11962 9.38954 4.03073 10.3007C4.94184 11.2118 6.04184 11.6673 7.33073 11.6673H12.9974V13.6673ZM8.7974 8.00065V6.00065H19.1307V8.00065H8.7974ZM27.3307 7.00065H25.3307C25.3307 5.71176 24.8752 4.61176 23.9641 3.70065C23.0529 2.78954 21.9529 2.33398 20.6641 2.33398H14.9974V0.333984H20.6641C22.5085 0.333984 24.0807 0.983984 25.3807 2.28398C26.6807 3.58398 27.3307 5.15621 27.3307 7.00065ZM21.2974 17.6673V13.6673H17.2974V11.6673H21.2974V7.66732H23.2974V11.6673H27.2974V13.6673H23.2974V17.6673H21.2974Z"
                        fill="#333333"
                      />
                    </svg>
                  </p>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div
            className="editor-left-panel-content"
            style={{ display: eventKey ? "" : "none" }}
          >
            <Tab.Content>
              <PageTab />
              {bookdetails?.isBookChapterWise ? <ChapterTab /> : null}
              {/* <Tab.Pane eventKey="third">
                <div className="add-image-format-tab">
                  <ul>
                    <li>
                      <img src={imageformat1} alt="Image format"></img>
                    </li>
                    <li>
                      <img src={imageformat2} alt="Image format"></img>
                    </li>
                    <li>
                      <img src={imageformat3} alt="Image format"></img>
                    </li>
                  </ul>
                </div>
              </Tab.Pane> */}
              <ImageTab />
              <UpinTab />
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};

export default EditorLeftPanel;
