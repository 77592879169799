import { Col, Modal, ModalBody, Row } from "react-bootstrap";
import { CustomButton } from "../../Button/CustomButton";
import { useEffect, useState } from "react";
import { TbDeviceAudioTape, TbVideo } from "react-icons/tb";
import { RxFileText, RxImage } from "react-icons/rx";
import { AiFillYoutube } from "react-icons/ai";
import AddTextModal from "./AddTextModal";
import AudioModal from "./AudioModal";
import ImageModal from "./ImageModal";
import VideoModal from "./VideoModal";
import { UVideoModal } from "./UVideoModal";
import { useUpinDetailsData } from "../../../store/upinStore";
import { addUpinApi, updateUpinApi } from "../../../api/api";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { successMsg } from "../../../constant";
const UpinModal = ({ isOpen, toggle, pindata, close }) => {
  const [loading, setLoading] = useState(false);
  const id = useParams().id;

  const [upins, setUpins] = useUpinDetailsData();

  const [openText, setOpenText] = useState(false);
  const [audioModal, setAudioModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [uvideoModal, setUVideoModal] = useState(false);

  const [textModalData, setTextModalData] = useState({
    title: "",
    description: "",
  });
  const [audio, setAudio] = useState("");
  const [video, setVideo] = useState("");
  const [img, setImg] = useState("");
  const [uvideo, setUVideo] = useState("");
  useEffect(() => {
    if (pindata) {
      setAudio(pindata?.audio);
      setVideo(pindata?.video);
      setImg(pindata?.img);
      setUVideo(pindata?.uvideo);
      setTextModalData({
        description: pindata?.description,
        title: pindata?.title,
      });
    } else {
      setAudio("");
      setVideo("");
      setImg("");
      setUVideo("");
      setTextModalData({
        description: "",
        title: "",
      });
    }
  }, [pindata]);
  const handleApi = async () => {
    if (!textModalData.title) return toast.error("Title is required!");
    setLoading(true);
    if (!pindata) {
      const [d, e] = await addUpinApi({
        bookID: id,
        authorID: localStorage.getItem("authorID"),
        audio,
        video,
        uvideo,
        img,
        ...textModalData,
      });
      if (!e && d?.upin) {
        const data = upins ? [...upins, d.upin] : [d.upin];
        setUpins(data);
        toast.success(successMsg.UPIN_ADD);
      }
    } else {
      const updatedData = {
        upinID: pindata?._id,
        bookID: id,
        authorID: localStorage.getItem("authorID"),
        audio,
        video,
        uvideo,
        img,
        ...textModalData,
      };
      const [d, e] = await updateUpinApi(updatedData);
      if (!e && d?.updatedUpin) {
        const data = [...upins];
        const findIndex = data?.findIndex((d) => d._id === pindata._id);
        if (findIndex !== 1)
          data[findIndex] = { ...d.updatedUpin, ...updatedData };
        setUpins(data);
        toast.success(successMsg.UPIN_EDIT);
      }
    }
    toggle();
    setLoading(false);
  };
  return (
    <>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton onHide={close}>
          <Modal.Title>{pindata ? "Edit" : "Add"} Upin</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Row>
            <Col xs={8} style={{ borderRight: "1px solid #eaeaea" }}>
              <Row>
                <Col
                  style={{ borderRight: "1px solid #eaeaea" }}
                  className="pb-2"
                  role="button"
                  onClick={() => {
                    toggle();
                    setAudioModal(true);
                  }}
                >
                  <Row>
                    <TbDeviceAudioTape size="38" />
                  </Row>
                  <Row className="d-flex justify-content-center mt-1">
                    Add Audio
                  </Row>
                </Col>
                <Col
                  className="pb-2"
                  role="button"
                  onClick={() => {
                    toggle();
                    setOpenText(true);
                  }}
                >
                  <Row>
                    <RxFileText size="38" />
                  </Row>
                  <Row className="d-flex justify-content-center mt-1">
                    Add Text
                  </Row>
                </Col>
              </Row>
              <Row style={{ borderTop: "1px solid #eaeaea" }}>
                <Col
                  style={{ borderRight: "1px solid #eaeaea" }}
                  className="pt-2"
                  role="button"
                  onClick={() => {
                    toggle();
                    setImgModal(true);
                  }}
                >
                  <Row>
                    <RxImage size="38" />
                  </Row>
                  <Row className="d-flex justify-content-center mt-1">
                    Add Image
                  </Row>
                </Col>
                <Col
                  className="pt-2"
                  role="button"
                  onClick={() => {
                    toggle();
                    setVideoModal(true);
                  }}
                >
                  <Row>
                    <TbVideo size="38" />
                  </Row>
                  <Row className="d-flex justify-content-center mt-1">
                    Add Video
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              role="button"
              xs={4}
              className="d-flex justify-content-center flex-column"
              onClick={() => {
                toggle();
                setUVideoModal(true);
              }}
            >
              <Row>
                <AiFillYoutube size="38" />
              </Row>
              <Row className="d-flex justify-content-center mt-1">
                Youtube Video
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <Modal.Footer>
          <div className="search-btn-container">
            <CustomButton
              text={`${pindata ? "Edit" : "Add"} Upin`}
              loading={loading}
              onClick={handleApi}
            />
          </div>
        </Modal.Footer>
      </Modal>
      <AddTextModal
        isOpen={openText}
        toggle={() => {
          setOpenText(false);
          toggle();
        }}
        cdata={textModalData}
        addHandle={(d) => {
          toggle();
          setTextModalData(d);
          setOpenText(false);
        }}
      />
      <AudioModal
        isOpen={audioModal}
        toggle={() => {
          setAudioModal(false);
          toggle();
        }}
        cdata={audio}
        addHandle={(d) => {
          setAudioModal(false);
          toggle();
          setAudio(d);
        }}
      />
      <ImageModal
        isOpen={imgModal}
        toggle={() => {
          setImgModal(false);
          toggle();
        }}
        cdata={img}
        addHandle={(d) => {
          setImgModal(false);
          toggle();
          setImg(d);
        }}
      />
      <VideoModal
        isOpen={videoModal}
        toggle={() => {
          setVideoModal(false);
          toggle();
        }}
        cdata={video}
        addHandle={(d) => {
          setVideoModal(false);
          toggle();
          setVideo(d);
        }}
      />
      <UVideoModal
        isOpen={uvideoModal}
        toggle={() => {
          setUVideoModal(false);
          toggle();
        }}
        cdata={uvideo}
        addHandle={(d) => {
          setUVideoModal(false);
          toggle();
          setUVideo(d);
        }}
      />
    </>
  );
};

export default UpinModal;
