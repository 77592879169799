import React from "react";
import { SlNote } from "react-icons/sl";
import { MdOutlineAddTask } from "react-icons/md";

const EditorRightPanel = () => {
  return (
    <div className="editor-right-panel">
      <ul>
        <li>
          <p>
            <SlNote />
          </p>
          <span>Notes</span>
        </li>
        <li>
          <p>
            <MdOutlineAddTask />
          </p>
          <span>Tasks</span>
        </li>
      </ul>
    </div>
  );
};

export default EditorRightPanel;
