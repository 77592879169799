class Searcher {
  constructor(quill) {
    this.quill = quill;
    this.occurrencesIndices = [];
    this.currentIndex = 0;
    this.SearchedStringLength = 0;
    this.SearchedString = "";
    this.container = document.getElementById("search-container");

    document
      .getElementById("search")
      ?.addEventListener("click", this.search.bind(this));
    document
      .getElementById("search-input")
      ?.addEventListener("keyup", this.keyPressedHandler.bind(this));

    document
      .getElementById("replace")
      ?.addEventListener("click", this.replace.bind(this));
    document
      .getElementById("replace-all")
      ?.addEventListener("click", this.replaceAll.bind(this));
  }

  search() {
    //  remove any previous search
    this.removeStyle();
    this.SearchedString = document.getElementById("search-input").value;

    if (this.SearchedString) {
      let totalText = this.quill.getText();
      let re = new RegExp(this.SearchedString, "gi");
      let match = re.test(totalText);
      if (match) {
        let indices = (this.occurrencesIndices = totalText.getIndicesOf(
          this.SearchedString
        ));

        let length = (this.SearchedStringLength = this.SearchedString.length);

        indices.forEach((index) =>
          this.quill.formatText(index, length, "SearchedString", true)
        );
      } else {
        this.occurrencesIndices = null;
        this.currentIndex = 0;
      }
    } else {
      this.removeStyle();
    }
  }

  replace() {
    if (!this.SearchedString) return;

    // if no occurrences, then search first.
    if (!this.occurrencesIndices) this.search();
    if (!this.occurrencesIndices) return;

    let indices = this.occurrencesIndices;

    let oldString = document.getElementById("search-input").value;
    let newString = document.getElementById("replace-input").value;

    this.quill.deleteText(indices[this.currentIndex], oldString.length);
    this.quill.insertText(indices[this.currentIndex], newString);
    this.quill.formatText(
      indices[this.currentIndex],
      newString.length,
      "SearchedString",
      false
    );
    // update the occurrencesIndices.
    this.search();
  }

  replaceAll() {
    if (!this.SearchedString) return;
    let oldStringLen = document.getElementById("search-input").value.length;
    let newString = document.getElementById("replace-input").value;

    // if no occurrences, then search first.
    if (!this.occurrencesIndices) this.search();
    if (!this.occurrencesIndices) return;

    if (this.occurrencesIndices) {
      while (this.occurrencesIndices) {
        this.quill.deleteText(this.occurrencesIndices[0], oldStringLen);
        this.quill.insertText(this.occurrencesIndices[0], newString);

        // update the occurrencesIndices.
        this.search();
      }
    }
    this.removeStyle();
  }

  keyPressedHandler(e) {
    if (e.key === "Enter") {
      this.search();
    }
  }
  removeStyle() {
    this.quill.formatText(
      0,
      this.quill.getText().length,
      "SearchedString",
      false
    );
  }
}

// function for utility
String.prototype.getIndicesOf = function (searchStr) {
  let searchStrLen = searchStr.length;
  let startIndex = 0,
    index,
    indices = [];
  while (
    (index = this.toLowerCase().indexOf(searchStr.toLowerCase(), startIndex)) >
    -1
  ) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
};

export default Searcher;
