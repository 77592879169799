import EditorPageWrapper from "./editor-page-wrapper";
import EditorLeftPanel from "./editor-header/editor-left-panel";
import Editor from "./custom-editor";
import EditorRightPanel from "./editor-right-panel";
import EditorHeader from "./editor-header";

const MainEditor = () => {
  return (
    <>
      <div className="toolbar" id="qill-custom-toolbar">
        <EditorHeader />
      </div>
      <EditorPageWrapper>
        <EditorLeftPanel />
        <Editor />
        <EditorRightPanel />
      </EditorPageWrapper>
    </>
  );
};

export default MainEditor;
