const book = "/book/";
const chapter = "/chapter/";
const bookContent = "/bookContent/";
const author = "/author/";

export const apiUrls = {
  getBookApiUrl: `${book}getBookDetails`,
  deletUpinUrl: `${book}deleteUpin`,

  addChapterApiUrl: `${chapter}addChapter`,
  editChapterApiUrl: `${chapter}editChapter`,
  deleteChapterApiUrl: `${chapter}deleteChapter`,
  getChaptersApiUrl: `${chapter}getChapters`,

  addPageApiUrl: `${bookContent}addPage`,
  deletePageApiUrl: `${bookContent}deletePage`,
  updatePageApiUrl: `${bookContent}updatePage`,
  getPagesApiUrl: `${bookContent}getPages`,

  uploadAssetApiUrl: `${author}uploadAsset`,
  updatedAssetsApiUrl: `${author}updatedAssets`,
  deleteAssetsApiUrl: `${author}deleteAssets`,
  getAllAssetAuthorSearchSortApiUrl: `${author}getAllAssetAuthorSearchSort`,

  addUpinUrl: `${book}addUpin`,
  updateUpinUrl: `${book}updateUpin`,
  getUpinByBookUrl: `${book}getUpinsBookWise?bookID=`,
};
