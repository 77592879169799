import { useAtom, atom } from "jotai";

const chapterDetailsAtom = atom(null);

const useChapterDetailsData = () => useAtom(chapterDetailsAtom);
const chapeterIndex = atom(0);

export const useChapeterIndexData = () => useAtom(chapeterIndex);

export default useChapterDetailsData;
