import ErrorMessageField from "./ErrorMessageField";

const TextFieldComponent = ({
  placeholder,
  other = {},
  error = false,
  disabled = false,
  replaceClass = "",
  appendClass = "",
  label = "",
}) => {
  return (
    <div>
      {label ? <label>{label}</label> : null}
      <input
        className={`${replaceClass || "form-control"} ${appendClass}`}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        {...other}
      />
      <ErrorMessageField error={error} />
    </div>
  );
};
export default TextFieldComponent;
