import { useAtom, atom } from "jotai";

const currentPage = atom("");
const pageUpdateId = atom("");
export const usePageUpdateId = () => useAtom(pageUpdateId);

export const useCurrentPage = () => useAtom(currentPage);

const pageDetailsAtom = atom(null);

const usePageDetailsData = () => useAtom(pageDetailsAtom);

const pageIndex = atom(0);

export const usePageIndexData = () => useAtom(pageIndex);

export default usePageDetailsData;
