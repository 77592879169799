import { Quill } from "react-quill";

const Inline = Quill.import("blots/inline");
class Track extends Inline {
  static create(value) {
    let node = super.create(value);
    node.contentEditable = "false";
    node.classList.add("highlight-1");
    return node;
  }
  // // Overriding this method, in this particular case,
  // // is what causes the Delta returned as content by
  // // Quill to have the desired information.
  // static formats(domNode) {
  //   return super.formats(domNode);
  // }

  formats() {
    // Returns the formats list this class (this format).
    let formats = super.formats();
    // Inline has the domNode reference, which in this
    // case represents the SPAN, result defined in tagName.
    formats["SearchedString"] = Track.formats(this.domNode);
    // In the code above, it is as if we are adding this new format.
    return formats;
  }
}

Track.blotName = "SearchedString";
Track.className = "ql-searched-string";
Track.tagName = "div";
export default Track;
