import { Modal, ModalBody } from "react-bootstrap";
import { CustomButton } from "../../Button/CustomButton";
import { useRef } from "react";
import { useUpinDetailsData } from "../../../store/upinStore";

export const UpinSelectModal = ({ isOpen, toggle, handleAdd }) => {
  const seluref = useRef();
  const [upin] = useUpinDetailsData();
  return (
    <Modal show={isOpen} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upin select modal</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <select className="form-control mt-2" ref={seluref}>
          <option value="">Please Select upin</option>
          {upin?.length
            ? upin.map((c) => (
                <option value={c._id} key={c._id}>
                  {c.title}
                </option>
              ))
            : null}
        </select>
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={"Apply Upin"}
            onClick={() => handleAdd(seluref.current.value)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
