import {
  getAllAssetAuthorSearchSortApi,
  getBookApi,
  getChaptersApi,
  getPagesApi,
  getUpinApi,
} from "../api/api";

export const getIntialBookData = (bid) => {
  return async () => {
    if (!bid) return [null, "Book id is not found!"];
    const [bookData, bookError] = await getBookApi({
      bookID: bid,
    });
    if (bookError) return [null, bookError];
    let cdata;
    if (bookData?.book?.isBookChapterWise) {
      const [chapterData, chapterDataError] = await getChaptersApi({
        bookID: bid,
      });
      cdata = chapterData?.chapters;
      if (chapterDataError) return [null, chapterDataError];
    }
    const pageGetData = {
      bookID: bid,
    };
    if (cdata?.[0]?._id) pageGetData.chapterID = cdata[0]?._id;
    const [page, assets, upins] = await Promise.allSettled([
      getPagesApi(pageGetData),
      getAllAssetAuthorSearchSortApi({
        authorID: bookData?.book?.author,
        numberOfRecord: 100000,
        page: 1,
      }),
      getUpinApi(bid),
    ]);
    const upinData = upins?.value[0]?.upins || [];
    const pageData = page?.value[0]?.bookContent?.bookPages || [];
    const assetsData = assets.value[0]?.responseData || [];
    localStorage.setItem("authorID", bookData?.book?.author);
    return [
      {
        bookData: bookData?.book,
        cdata,
        pageData,
        assetsData,
        upinData,
        pageUpdateId: page?.value[0]?.bookContent?._id || "",
      },
      null,
    ];
  };
};
