import { asyncWrapper } from "../lib/asyncWrapper";
import { axiosInstanceBook, axiosInstanceAuthor } from "../lib/axiosInstance";
import { apiUrls } from "./apiUrls";
const serialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return `?${str.join("&")}`;
};
export const getBookApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.getBookApiUrl, data);

export const addChapterApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.addChapterApiUrl, data);
export const editChapterApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.editChapterApiUrl, data);
export const deleteChapterApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.deleteChapterApiUrl, data);
export const getChaptersApi = async (data) =>
  await asyncWrapper(
    axiosInstanceBook.get,
    apiUrls.getChaptersApiUrl + serialize(data)
  );

export const addPageApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.addPageApiUrl, data);
export const deletePageApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.deletePageApiUrl, data);
export const deleteUpinApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.deletUpinUrl, data);

export const updatePageApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.updatePageApiUrl, data);
export const getPagesApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.getPagesApiUrl, data);

export const uploadAssetApi = async (data) =>
  await asyncWrapper(
    axiosInstanceAuthor.postForm,
    apiUrls.uploadAssetApiUrl,
    data
  );
export const updatedAssetsApi = async (data) =>
  await asyncWrapper(
    axiosInstanceAuthor.postForm,
    apiUrls.updatedAssetsApiUrl,
    data
  );
export const deleteAssetsApi = async (data) =>
  await asyncWrapper(
    axiosInstanceAuthor.post,
    apiUrls.deleteAssetsApiUrl,
    data
  );
export const getAllAssetAuthorSearchSortApi = async (data) =>
  await asyncWrapper(
    axiosInstanceAuthor.post,
    apiUrls.getAllAssetAuthorSearchSortApiUrl,
    data
  );

export const addUpinApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.addUpinUrl, data);
export const updateUpinApi = async (data) =>
  await asyncWrapper(axiosInstanceBook.post, apiUrls.updateUpinUrl, data);
export const getUpinApi = async (id) =>
  await asyncWrapper(axiosInstanceBook.get, apiUrls.getUpinByBookUrl + id, "");
