import { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import TextFieldComponent from "../../TextFieldComponent/TextField";
import { CustomButton } from "../../Button/CustomButton";
import { AiFillDelete } from "react-icons/ai";

export const UVideoModal = ({ isOpen, toggle, cdata, addHandle }) => {
  const [video, setVideo] = useState();
  useEffect(() => {
    setVideo(cdata);
  }, [cdata]);
  const ref = useRef();
  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{cdata ? "Edit" : "Add"} Youtube Video</Modal.Title>
      </Modal.Header>

      <ModalBody>
        <div className="d-flex gap-2 flex-row mb-2">
          <TextFieldComponent
            appendClass="80%"
            placeholder="Enter embed link"
            other={{
              ref: ref,
            }}
          />
          <CustomButton
            text="Add"
            onClick={() => setVideo(ref.current?.value)}
          />
        </div>
        {video ? (
          <div className="d-flex justify-content-center">
            <iframe
              title="video"
              width="100%"
              height="200"
              src={video}
              allowFullScreen
              className="mb-2"
            />
            <AiFillDelete
              size={32}
              className="my-auto ms-2 text-danger"
              role="button"
              onClick={() => setVideo("")}
            />
          </div>
        ) : null}
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={`${cdata ? "Edit" : "Add"} Youtube Video`}
            onClick={() => addHandle(video)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
