import * as yup from "yup";
import { Modal, ModalBody } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { successMsg, validations } from "../../constant";
import { useEffect, useState } from "react";
import TextFieldComponent from "../TextFieldComponent/TextField";
import { CustomButton } from "../Button/CustomButton";
import { addChapterApi, editChapterApi } from "../../api/api";
import useChapterDetailsData from "../../store/chapterStore";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  "Chapter Name": yup
    .string()
    .required()
    .matches(
      validations.notAllowedSpace,
      "This field cannot contain only blankspaces"
    ),
  "Chapter Number": yup.number().positive().required(),
});
const AddChapterModal = ({ isOpen, toggle, cdata }) => {
  const [loading, setLoading] = useState(false);
  const [chData, setCh] = useChapterDetailsData();
  const id = useParams().id;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (cdata) {
      setValue("Chapter Name", cdata?.chapterName);
      setValue("Chapter Number", cdata?.chapterNumber);
    } else {
      setValue("Chapter Name", "");
      setValue("Chapter Number", "");
      reset();
    }
  }, [cdata]);
  const addHandle = async (data) => {
    setLoading(true);
    if (cdata) {
      const [, error] = await editChapterApi({
        chapterID: cdata._id,
        bookID: cdata.bookID,
        chapterNumber: data["Chapter Number"],
        chapterName: data["Chapter Name"],
      });
      if (!error) {
        const d = chData.map((d) =>
          d?._id === cdata?._id
            ? {
                ...d,
                chapterNumber: data["Chapter Number"],
                chapterName: data["Chapter Name"],
              }
            : d
        );
        setCh(d);
        toggle();
        toast.success(successMsg.CH_EDIT);
      }
    } else {
      const [ch, error] = await addChapterApi({
        chapterName: data["Chapter Name"],
        chapterNumber: data["Chapter Number"],
        bookID: id,
      });
      if (!error && ch.chapter) {
        const d = chData ? [...chData, ch.chapter] : [ch.chapter];
        setCh(d);
        toggle();
        toast.success(successMsg.CH_ADD);
      }
    }
    setLoading(false);
  };
  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{cdata ? "Edit" : "Add"} Chapter</Modal.Title>
      </Modal.Header>

      <ModalBody className="d-flex flex-column gap-3">
        <TextFieldComponent
          placeholder="Chapter Name"
          label="Chapter Name"
          error={errors?.["Chapter Name"]?.message}
          other={{
            ...register("Chapter Name"),
          }}
        />
        <TextFieldComponent
          placeholder="Chapter Number"
          label="Chapter Number"
          error={errors?.["Chapter Number"]?.message}
          other={{
            ...register("Chapter Number"),
          }}
        />
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={`${cdata ? "Edit" : "Add"} Chapter`}
            onClick={handleSubmit(addHandle)}
            loading={loading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddChapterModal;
