import { Modal, ModalBody } from "react-bootstrap";
import { CustomButton } from "../Button/CustomButton";

const DeleteConfirmModal = ({
  isOpen,
  toggle,
  text,
  title,
  onDelete,
  loader,
}) => {
  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {title}</Modal.Title>
      </Modal.Header>

      <ModalBody>{text}</ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton text="Cancel" onClick={toggle} />
          <CustomButton
            text="Delete"
            loading={loader}
            onClick={onDelete}
            classReplace="btn-danger"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmModal;
