import { useState, useSyncExternalStore } from "react";
import { Button, Card } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { upinStore } from "../../modules/upin/store";
import { useUpinDetailsData } from "../../store/upinStore";
import { getAssestsUrl } from "../../helper/getAssestsUrl";
const getXandYposition = (event) => {
  const scrollableDiv = document.getElementById("ql-outerdiv");

  const rect = scrollableDiv?.getBoundingClientRect() || "";

  const mouseX = (event?.clientX || 0) - (rect?.left || 0);
  const mouseY = (event?.clientY || 0) - (rect?.top || 0);

  // Output the mouse position
  return { top: mouseY, left: mouseX };
};
const Upincard = ({ Quill, qill }) => {
  const upinData = useSyncExternalStore(
    upinStore.subscribe,
    upinStore.getSnapshot
  );
  const [readMore, setReadMore] = useState(false);
  const toggle = () => upinStore.showUpin(null);

  const [upins] = useUpinDetailsData();

  const upinDataset = upinData?.id
    ? upins.find((f) => f._id === upinData.id)
    : null;

  return (
    <Card
      style={{
        width: "20rem",
        position: "absolute",
        top: getXandYposition(upinData?.event)?.top || 0,
        left: getXandYposition(upinData?.event)?.left || 0,
        display: upinData ? "" : "none",
        zIndex: 90,
      }}
    >
      <AiOutlineCloseCircle
        size={34}
        style={{
          position: "absolute",
          right: 0,
          color: "red",
          cursor: "pointer",
          zIndex: 99,
        }}
        onClick={toggle}
      />
      {upinDataset?.img ? (
        <Card.Img variant="top" src={getAssestsUrl(upinDataset.img)} />
      ) : null}

      <div
        className={`d-flex flex-column gap-4 ${
          upinDataset?.img ? "mt-2" : "mt-4"
        }`}
      >
        {upinDataset?.uvideo ? (
          <iframe
            title="video"
            width="100%"
            height="200"
            src={upinDataset.uvideo}
            allowFullScreen
          />
        ) : null}
        {upinDataset?.video ? (
          <video width="100%" controls>
            <source src={getAssestsUrl(upinDataset.video)} />
            Your browser does not support HTML video.
          </video>
        ) : null}
        {upinDataset?.audio ? (
          <div className="d-flex justify-content-center">
            <audio controls controlsList="noplaybackrate nodownload">
              <source src={getAssestsUrl(upinDataset.audio)} />
              Your browser does not support the audio element.
            </audio>
          </div>
        ) : null}
      </div>
      <Card.Body>
        {!upinDataset ? (
          <Card.Title className="h5 mt-1">
            It's look like you remove this upin! please click remove to remove
            from editor.
          </Card.Title>
        ) : null}
        {upinDataset?.title ? (
          <Card.Title className="h5 mt-1">{upinDataset.title}</Card.Title>
        ) : null}
        {upinDataset?.description ? (
          <Card.Text style={{ fontWeight: 300, fontSize: "14px" }}>
            {!readMore
              ? upinDataset.description?.slice(0, 100) || ""
              : upinDataset.description}
            {upinDataset.description?.length > 100 ? (
              <div
                onClick={() => setReadMore(!readMore)}
                style={{
                  color: "purple",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  marginTop: "10px",
                }}
              >
                {readMore ? "Less" : "Read More"}{" "}
              </div>
            ) : null}
          </Card.Text>
        ) : null}
      </Card.Body>
      <Card.Footer style={{ textAlign: "right" }}>
        <Button
          style={{
            fontSize: "14px",
            padding: "10px",
          }}
          onClick={() => {
            let blot = Quill.find(upinData?.event.target);
            let index = blot.offset(qill.scroll);
            qill.formatText(index, blot.length(), "upin", false);
            toggle();
          }}
        >
          Remove upin
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default Upincard;
