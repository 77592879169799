import { useEffect, useState } from "react";
import { Card, Col, Modal, ModalBody, Row, Tab, Tabs } from "react-bootstrap";
import { uploadAssetApi } from "../../../api/api";
import { AiFillDelete } from "react-icons/ai";
import CustomDropzone from "../../dropzone/CustomDropzone";
import { maxSizes } from "../../../constant";
import { CustomButton } from "../../Button/CustomButton";
import { TbVideo } from "react-icons/tb";
import useAssetData from "../../../store/assetStore";
import { getAssestsUrl } from "../../../helper/getAssestsUrl";

const VideoModal = ({ isOpen, toggle, cdata, addHandle }) => {
  const [video, setVideo] = useState();
  useEffect(() => {
    setVideo(cdata);
  }, [cdata]);

  const [assestsData, setAssestsData] = useAssetData();
  const fData =
    assestsData && assestsData.length
      ? assestsData.filter((a) => a.type === 3)
      : [];
  const handleDrop = async (file) => {
    const fileData = new FormData();
    fileData.append("authorID", localStorage.getItem("authorID"));
    fileData.append("assetUrl", file[0]);
    const [data, error] = await uploadAssetApi(fileData);
    if (!error && data?.responseData?.assetUrl) {
      setVideo(data?.responseData?.assetUrl);
      setAssestsData([...assestsData, data.responseData]);
    }
  };
  return (
    <Modal show={isOpen} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{cdata ? "Edit" : "Add"} Video</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <Tabs
          defaultActiveKey="Upload"
          id="controlled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Upload" title="Upload">
            {video ? (
              <div className="d-flex justify-content-center">
                <video width="400" controls>
                  <source src={getAssestsUrl(video)} />
                  Your browser does not support HTML video.
                </video>
                <AiFillDelete
                  size={32}
                  className="my-auto ms-2 text-danger"
                  role="button"
                  onClick={() => setVideo("")}
                />
              </div>
            ) : (
              <CustomDropzone
                isMulti={false}
                maxSize={maxSizes.OTHER}
                handleDrop={handleDrop}
                accept={{
                  "video/mkv": [".mkv"],
                  "video/mp4": [".mp4"],
                  "video/webm": [".webm"],
                  "video/x-m4v": [".m4v"],
                  "video/quicktime": [".mov", ".qt"],
                }}
                component={
                  <div className="dropzone d-flex text-center flex-column justify-content-center">
                    <TbVideo size="48" className="m-auto mb-2" />
                    Drag and drop your image file
                    <br />
                    <p className="text-secondary mt-1">
                      Upload your image file here to attach with word & sentence
                    </p>
                  </div>
                }
                textComponent=""
              />
            )}
          </Tab>
          <Tab eventKey="My Assets" title="My Assets">
            <Card
              style={{ maxHeight: "400px" }}
              className="p-2 d-flex gap-2 overflow-auto"
            >
              <Row className="m-0">
                {fData.map((a) => (
                  <Col key={a._id} className="my-auto" xs={6}>
                    <video controls>
                      <source src={getAssestsUrl(video)} />
                      Your browser does not support HTML video.
                    </video>
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "30px",
                      }}
                    >
                      <CustomButton
                        text={video === a.assetUrl ? "Remove" : "Add"}
                        onClick={() =>
                          setVideo(video === a.assetUrl ? "" : a.assetUrl)
                        }
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Card>
          </Tab>
        </Tabs>
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={`${cdata ? "Edit" : "Add"} Video`}
            onClick={() => addHandle(video)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoModal;
