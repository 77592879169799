import AddNoteSidebar from "./add-note-sidebar";
import AddTaskSidebar from "./add-task-sidebar";

const Notes = () => {
  return (
    <>
      <AddNoteSidebar />
      <AddTaskSidebar />
    </>
  );
};

export default Notes;
