import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, ModalBody } from "react-bootstrap";
import TextFieldComponent from "../../TextFieldComponent/TextField";
import { CustomButton } from "../../Button/CustomButton";
import { validations } from "../../../constant";
import { useEffect } from "react";

const schema = yup.object().shape({
  title: yup
    .string()
    .required()
    .matches(
      validations.notAllowedSpace,
      "This field cannot contain only blankspaces"
    ),
  description: yup.string(),
});
const AddTextModal = ({ isOpen, toggle, cdata, addHandle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    setValue("title", cdata?.title || "");
    setValue("description", cdata?.description || "");
  }, [cdata]);

  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>{cdata ? "Edit" : "Add"} Text</Modal.Title>
      </Modal.Header>

      <ModalBody>
        <div className="d-flex gap-2 flex-column ">
          <TextFieldComponent
            label="Title"
            placeholder="Enter Title"
            error={errors?.["title"]?.message}
            other={{
              ...register("title"),
            }}
          />
          <TextFieldComponent
            label="Description"
            placeholder="Enter Description"
            error={errors?.["description"]?.message}
            other={{
              ...register("description"),
            }}
          />
        </div>
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={`${cdata ? "Edit" : "Add"} Text`}
            onClick={handleSubmit(addHandle)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTextModal;
