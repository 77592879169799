import { useParams } from "react-router-dom";
import MainEditor from "./components/editor";
import EditorFooter from "./components/editor/editor-footer";
import Notes from "./components/notes";
import Header from "./components/page-header";
import { getIntialBookData } from "./helper/getIntialBookData";
import { useApi } from "./hooks/useApi";
import useBookData from "./store/bookStore";
import useChapterDetailsData from "./store/chapterStore";
import usePageDetailsData, { usePageUpdateId } from "./store/pageStore";
import useAssetData from "./store/assetStore";
import Spinner from "./ui/loader/Loader";
import { useUpinDetailsData } from "./store/upinStore";

const Main = () => {
  const id = useParams().id;

  const [, setBookData] = useBookData();
  const [, setChapeterData] = useChapterDetailsData();
  const [, setPageData] = usePageDetailsData();
  const [, setAssetsData] = useAssetData();
  const [, setUpinData] = useUpinDetailsData();
  const [, setUpdateId] = usePageUpdateId();

  const { isLoading } = useApi({
    fun: getIntialBookData(id),
    trigger: (d) => {
      setAssetsData(d?.assetsData || []);
      setBookData(d?.bookData);
      setChapeterData(d?.cdata);
      setPageData(d?.pageData);
      setUpinData(d?.upinData || []);
      setUpdateId(d.pageUpdateId);
    },
  });
  return (
    <main style={{ minWidth: "1200px" }}>
      <Header />
      <MainEditor />
      <EditorFooter />
      <Notes />
      {isLoading ? <Spinner /> : null}
    </main>
  );
};

export default Main;
