import { useState } from "react";
import { Spinner, Tab } from "react-bootstrap";
import A41 from "../../../../src/images/A4-1.png";
import A42 from "../../../../src/images/A4-2.png";
import A43 from "../../../../src/images/A4-3.png";
import A44 from "../../../../src/images/A4-4.png";
import useBookData from "../../../store/bookStore";
import useChapterDetailsData, {
  useChapeterIndexData,
} from "../../../store/chapterStore";
import { toast } from "react-hot-toast";
import {
  pageWordCountConstant,
  pagesContent,
  successMsg,
} from "../../../constant";
import { addPageApi } from "../../../api/api";
import usePageDetailsData, {
  usePageIndexData,
  usePageUpdateId,
} from "../../../store/pageStore";

const PageTab = () => {
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [, setPageIndex] = usePageIndexData();
  const [, setPageData] = usePageDetailsData();
  const [, setUpdateId] = usePageUpdateId();

  const [bookd] = useBookData();
  const [ch] = useChapterDetailsData();
  const [cidx] = useChapeterIndexData();

  const addPage = async () => {
    setLoader(true);
    if (bookd?.isBookChapterWise && !ch?.length) {
      toast.error("Please add chapeter first!");
    } else {
      if (bookd?.isBookChapterWise && !ch[cidx]?._id) {
        toast.error("Please select chapeter first!");
        return;
      } else {
        const data = {
          bookID: bookd?._id,
          pageContent: pagesContent[page],
          wordCount: pageWordCountConstant[page],
        };
        if (ch?.[cidx]?._id) data.chapterID = ch[cidx]._id;
        const [d, e] = await addPageApi(data);
        if (!e && d?.page?.bookPages) {
          setPageData(d.page.bookPages);
          setPageIndex(d.page.bookPages.length - 1);
          setUpdateId(d.page._id);
          toast.success(successMsg.PAGE_ADD);
        }
      }
    }
    setLoader(false);
  };
  return (
    <Tab.Pane eventKey="first">
      <div className="add-page-content">
        <div className="btn-wrap" onClick={addPage}>
          <button className="btn btn-green">
            {loader ? <Spinner size="sm" /> : "Add Page"}
          </button>
        </div>
        <ul>
          <li
            className={page === 0 ? "active-page" : ""}
            onClick={() => setPage(0)}
          >
            <img src={A41} alt="page format"></img>
          </li>
          <li
            className={page === 1 ? "active-page" : ""}
            onClick={() => setPage(1)}
          >
            <img src={A42} alt="page format"></img>
          </li>
          <li
            className={page === 2 ? "active-page" : ""}
            onClick={() => setPage(2)}
          >
            <img src={A43} alt="page format"></img>
          </li>
          <li
            className={page === 3 ? "active-page" : ""}
            onClick={() => setPage(3)}
          >
            <img src={A44} alt="page format"></img>
          </li>
        </ul>
      </div>
    </Tab.Pane>
  );
};

export default PageTab;
