import Form from "react-bootstrap/Form";
// import Dropdown from "react-bootstrap/Dropdown";
// import { MdArrowDropDown } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import useBookData from "../store/bookStore";
import { updatePageApi } from "../api/api";
import { getWordCount } from "../constant";
import usePageDetailsData, {
  useCurrentPage,
  usePageIndexData,
  usePageUpdateId,
} from "../store/pageStore";
import { useCallback, useEffect, useState } from "react";
const SAVE_INTERVAL_MS = 30000;

const Header = () => {
  const [bookData] = useBookData();
  const [updateId] = usePageUpdateId();
  const [pages] = usePageDetailsData();
  const [pgd] = useCurrentPage();
  const [pageIndex] = usePageIndexData();

  const [loading, setLoading] = useState(false);

  const savePage = useCallback(async () => {
    if (!updateId || pgd === pages[pageIndex]?.pageContent) return;
    setLoading(true);
    await updatePageApi({
      pageContent: pgd,
      pageID: updateId,
      wordCount: getWordCount(),
      pageNumber: pages[pageIndex].pageNumber,
    });
    setLoading(false);
  }, [updateId, pgd, pages, pageIndex]);

  useEffect(() => {
    if (!updateId || !pages || !pages[pageIndex]) return;
    const id = setInterval(savePage, SAVE_INTERVAL_MS);
    return () => {
      clearInterval(id);
    };
  }, [pageIndex, pages, pgd, updateId, savePage]);

  return (
    <div className="ck-editor-header">
      <div className="header-content">
        <a href="#" className="home">
          <BsArrowLeft className="fs-20" />
          Home
        </a>
        <div className="select-book-dropdown">
          <div className="input-box without-background">
            {bookData?.bookName || ""}
          </div>
          {/* <Dropdown className="input-box without-background">
            <Dropdown.Toggle id="dropdown-basic">
              E-book / The man and Superman
              <MdArrowDropDown className="fs-20 ms-1" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Male</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Female</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Other</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        <div className="btn-wrap">
          <Form>
            <p>Light</p>
            <Form.Check type="switch" id="custom-switch" label="Dark" />
          </Form>
          <button
            className="btn btn-primary"
            onClick={savePage}
            disabled={loading}
          >
            {loading ? "Loading..." : "Save"}
          </button>
          <button className="btn btn-green">Upload</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
