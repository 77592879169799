import { Card, Col, Modal, ModalBody, Row, Tab, Tabs } from "react-bootstrap";
import { CustomButton } from "../../Button/CustomButton";
import { useEffect, useState } from "react";
import CustomDropzone from "../../dropzone/CustomDropzone";
import { TbDeviceAudioTape } from "react-icons/tb";
import { uploadAssetApi } from "../../../api/api";
import { AiFillDelete } from "react-icons/ai";
import { maxSizes } from "../../../constant";
import useAssetData from "../../../store/assetStore";
import { getAssestsUrl } from "../../../helper/getAssestsUrl";

const AudioModal = ({ isOpen, toggle, cdata, addHandle }) => {
  const [audio, setAudio] = useState();
  useEffect(() => {
    setAudio(cdata);
  }, [cdata]);
  const [assestsData, setAssestsData] = useAssetData();
  const fData =
    assestsData && assestsData.length
      ? assestsData.filter((a) => a.type === 2)
      : [];
  const handleDrop = async (file) => {
    const fileData = new FormData();
    fileData.append("authorID", localStorage.getItem("authorID"));
    fileData.append("assetUrl", file[0]);
    const [data, error] = await uploadAssetApi(fileData);
    if (!error && data?.responseData?.assetUrl) {
      setAudio(data?.responseData?.assetUrl);
      setAssestsData([...assestsData, data.responseData]);
    }
  };
  return (
    <Modal show={isOpen} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{cdata ? "Edit" : "Add"} Audio</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <Tabs
          defaultActiveKey="Upload"
          id="controlled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Upload" title="Upload">
            {audio ? (
              <div className="d-flex justify-content-center">
                <audio controls controlsList="noplaybackrate nodownload">
                  <source src={getAssestsUrl(audio)} />
                  Your browser does not support the audio element.
                </audio>
                <AiFillDelete
                  size={32}
                  className="my-auto ms-2 text-danger"
                  role="button"
                  onClick={() => setAudio("")}
                />
              </div>
            ) : (
              <CustomDropzone
                isMulti={false}
                maxSize={maxSizes.OTHER}
                handleDrop={handleDrop}
                accept={{
                  "audio/mpeg": [".mp3", ".mpeg"],
                  "audio/ogg": [".ogg"],
                  "audio/x-wav": [".wav", ".wave"],
                }}
                component={
                  <div className="dropzone d-flex text-center flex-column justify-content-center">
                    <TbDeviceAudioTape size="48" className="m-auto mb-2" />
                    Drag and drop your audio file
                    <br />
                    <p className="text-secondary mt-1">
                      Upload your audio file here to attach with word & sentence
                    </p>
                  </div>
                }
                textComponent=""
              />
            )}
          </Tab>
          <Tab eventKey="My Assets" title="My Assets">
            <Card
              style={{ maxHeight: "200px" }}
              className="p-2 d-flex gap-2 overflow-auto"
            >
              {fData.map((a) => (
                <Row key={a._id} className="m-0">
                  <Col className="my-auto" xs={10}>
                    <audio controls controlsList="noplaybackrate nodownload">
                      <source src={getAssestsUrl(a.assetUrl)} />
                      Your browser does not support the audio element.
                    </audio>
                  </Col>
                  <Col>
                    <CustomButton
                      text={audio === a.assetUrl ? "Remove" : "Add"}
                      onClick={() =>
                        setAudio(audio === a.assetUrl ? "" : a.assetUrl)
                      }
                    />
                  </Col>
                </Row>
              ))}
            </Card>
          </Tab>
        </Tabs>
      </ModalBody>
      <Modal.Footer>
        <div className="search-btn-container">
          <CustomButton
            text={`${cdata ? "Edit" : "Add"} Audio`}
            onClick={() => addHandle(audio)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AudioModal;
